import cx from "classnames";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import AppContext from "src/app/AppContext";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";
import useDateFormat from "src/app/hooks/useDateFormat";
import { HomePageContext } from "src/app/pages/Home";
import { getScreenOrientation } from "src/app/utils/getScreenOrientation";
import {
  EP_GA_EVENT_NAME,
  GAClickAction,
  getGAPublicationName,
  getTrackerDateString,
  PAGE_NAME,
  useEpGTMDispatch,
} from "src/trackers/googleAnalytics";

import IssueLoader from "../IssueLoader";
import styles from "./index.module.scss";

type TileProps = {
  publishedDate: Date | undefined;
  coverImageUri: string | undefined;
  theme: PublicationShortname | null;
  toggleTileView: () => void;
};

type TileDateProps = {
  theme: PublicationShortname | null;
  publishedDate: Date | undefined;
};

const TileDate = ({ theme, publishedDate }: TileDateProps) => {
  return (
    <>
      <p data-theme={theme} className={cx(styles.tileDay)}>
        {publishedDate &&
          useDateFormat({
            date: publishedDate as unknown as Date,
            shouldShowDayOfWeek: true,
          })}
      </p>
      <p data-theme={theme} className={cx(styles.tileDate)}>
        {publishedDate &&
          useDateFormat({
            date: publishedDate as unknown as Date,
          })}
      </p>
    </>
  );
};

//component for single issue tile
const Tile = ({
  publishedDate,
  toggleTileView,
  coverImageUri,
  theme,
}: TileProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const { selectedDateMap, setSelectedDateMap } = useContext(HomePageContext);
  const { pubId, visitorContext } = useContext(AppContext);
  const gtmDispatch = useEpGTMDispatch();

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false);
    }
  }, [theme]);

  return (
    <button
      key={publishedDate}
      onClick={() => {
        if (publishedDate) {
          const updatedSelectedDateMap = new Map([
            ...selectedDateMap.entries(),
          ]).set(pubId, publishedDate as unknown as Date);
          setSelectedDateMap(updatedSelectedDateMap);

          if (visitorContext) {
            gtmDispatch({
              eventName: EP_GA_EVENT_NAME.CUSTOM,
              eventDetail: {
                clickAction: GAClickAction.CLICK,
                clickLabel: `archive::${dayjs(publishedDate).format("YYYY-MM-DD")}`,
                issue_pub_date: getTrackerDateString(publishedDate),
                chapter1: PAGE_NAME.PORTAL,
                pageName: PAGE_NAME.PORTAL,
                visitorCategory: visitorContext.visitorCategory,
                visitorId: visitorContext.visitorId,
                svc: visitorContext.svc,
                orientation: getScreenOrientation(),
                publication_name: getGAPublicationName(pubId),
              },
            });
          }
        }
        toggleTileView();
      }}
    >
      <IssueLoader
        forTileView={true}
        imageSrc={coverImageUri}
        isLoaded={isLoaded}
        handleImageLoad={handleImageLoad}
      />
      {isLoaded && <TileDate theme={theme} publishedDate={publishedDate} />}
    </button>
  );
};

export default Tile;
