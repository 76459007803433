import Cookies from "js-cookie";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "src/app/AppContext";
import {
  getLanguageByPubId,
  getSubscribeNowUrlByPubId,
  NO_AUTH_PUBLICATION,
  PublicationShortname,
} from "src/app/auth/serviceIdentifier";
import { getScreenOrientation } from "src/app/utils/getScreenOrientation";
import {
  EP_GA_EVENT_NAME,
  GAClickAction,
  getGAAuthenticationStatus,
  getGAPublicationName,
  getTrackerDateString,
  PAGE_NAME,
  useEpGTMDispatch,
} from "src/trackers/googleAnalytics";
import { DEFAULT_VISITOR_CONTEXT } from "src/trackers/viewerContext";

import assertEnum from "../../../../.web2/web2-helpers/assertEnum";
import logo from "../../assets/SPH24-Logo.svg";
import subscribeStatusIcon from "../../assets/subscribe-status-icon.svg";
import { HomePageContext } from "../../pages/Home";
import { COOKIE_KEY_PUBS_ID, TGtmData } from "../Home";
import styles from "./SubscribePage.module.scss";

enum ISSUE_GA_CLICK_LABEL {
  SUBSCRIBE_NOW = "subscribe now",
  SUBSCRIBE_LATER = "not now",
}

export default function SubscribePage(): ReactElement {
  const { i18n, t } = useTranslation();
  const [notNowRoute, setNotNowRoute] = useState<string | null>(null);
  const [subscribeNow, setSubscribeNow] = useState<string | null>(null);
  const [lang, setLang] = useState<string>("en");
  const { pubId, visitorContext } = useContext(AppContext);
  const { selectedDateMap } = useContext(HomePageContext);
  const currentSelectedDate =
    selectedDateMap.size == 0 ? new Date() : selectedDateMap.get(pubId);
  const navigate = useNavigate();
  const gtmDispatch = useEpGTMDispatch();

  useEffect(() => {
    const redirectionPath =
      pubId == PublicationShortname.DEFAULT ? PublicationShortname.ST : pubId;
    if (NO_AUTH_PUBLICATION.includes(pubId)) {
      navigate(`/${redirectionPath}`);
    } else {
      setNotNowRoute(`/${redirectionPath}`);
      setSubscribeNow(getSubscribeNowUrlByPubId(redirectionPath));
      setLang(getLanguageByPubId(redirectionPath));
    }
  }, [pubId, navigate]);

  useEffect(() => {
    if (pubId != PublicationShortname.DEFAULT)
      handleGAEvent(EP_GA_EVENT_NAME.PAGE_VIEW, {
        issue_pub_date: getTrackerDateString(currentSelectedDate),
        chapter1: PAGE_NAME.PORTAL,
        pageName: PAGE_NAME.SUBSCRIBE,
      });
  }, [pubId]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleNotNow = () => {
    if (pubId != PublicationShortname.DEFAULT) {
      handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
        clickAction: GAClickAction.CLICK,
        clickLabel: ISSUE_GA_CLICK_LABEL.SUBSCRIBE_LATER,
        issue_pub_date: getTrackerDateString(currentSelectedDate),
        chapter1: PAGE_NAME.PORTAL,
        pageName: PAGE_NAME.SUBSCRIBE,
      });
    }
    if (notNowRoute) {
      navigate(notNowRoute);
    }
  };

  const handleSubscribeNow = () => {
    if (pubId != PublicationShortname.DEFAULT) {
      handleGAEvent(EP_GA_EVENT_NAME.CUSTOM, {
        clickAction: GAClickAction.CLICK,
        clickLabel: ISSUE_GA_CLICK_LABEL.SUBSCRIBE_NOW,
        issue_pub_date: getTrackerDateString(currentSelectedDate),
        chapter1: PAGE_NAME.PORTAL,
        pageName: PAGE_NAME.SUBSCRIBE,
      });
    }
    if (subscribeNow) {
      window.location.href = subscribeNow;
    }
  };

  const handleGAEvent = (eventName: EP_GA_EVENT_NAME, gtmData?: TGtmData) => {
    if (visitorContext !== null) {
      const GAContext = NO_AUTH_PUBLICATION.includes(pubId)
        ? DEFAULT_VISITOR_CONTEXT
        : visitorContext;

      gtmDispatch({
        eventName: eventName,
        eventDetail: {
          ...gtmData,
          pagination: "1",
          visitorCategory: GAContext.visitorCategory,
          visitorId: GAContext.visitorId,
          svc: GAContext.svc,
          orientation: getScreenOrientation(),
          publication_name: getGAPublicationName(pubId),
        },
      });
    }
  };

  return (
    <div className={styles.subscribePage}>
      <div className={styles.subscribePageContainer}>
        <div className={styles.subscribePageContainerHeader}>
          <div className={styles.logo}>
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className={styles.subscribePageContainerStatusIcon}>
          <img src={subscribeStatusIcon} alt="Subscribe status icon" />
        </div>
        <div className={styles.subscribePageContainerTitle}>
          {t("subscribe:title")}
        </div>
        <div className={styles.subscribePageContainerDescription}>
          {t("subscribe:body")}
        </div>
        <div className={styles.subscribePageContainerSubscribeNowButton}>
          <button onClick={handleSubscribeNow}>{t("subscribe:cta")}</button>
        </div>
        <div className={styles.subscribePageContainerNotNowButton}>
          <button onClick={handleNotNow}>
            {t("subscribe:backToPublicationLink")}
          </button>
        </div>
      </div>
    </div>
  );
}
