import * as Sentry from "@sentry/react";
import { AuthCallback, LogoutCallback } from "@sphtech/web2-core/auth";
import { fetchContext, ResponseType } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";
import HomePage from "src/app/pages/Home";

import {
  getClientId,
  getIssuer,
} from "../../.web2/web2-helpers/auth/MySphConfig";
import App from "./App";
import { getCookiePath } from "./auth/cookiePath";
import { PublicationShortname } from "./auth/serviceIdentifier";
import ConcurrencyPage from "./pages/Concurrency";
import CorpCallbackPage from "./pages/CorpCallback";
import ErrorPage from "./pages/ErrorPage";
import FallbackPage from "./pages/Fallback";
import SubscribePage from "./pages/SubscribePage";
import UnauthenticatedPage from "./pages/Unauthenticated";
import { Language } from "./types/Data.d";

const errorElement = import.meta.env.PROD ? <ErrorPage /> : undefined;

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        index: true,
        element: <FallbackPage />,
        errorElement,
      },
      /**
       * MySPH Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */
      {
        path: "/oauth/callback",
        element: (
          <AuthCallback
            clientId={getClientId()}
            issuer={getIssuer()}
            getCookiePath={getCookiePath}
            sentry={Sentry}
          />
        ),
        errorElement,
      },
      {
        path: "/oauth/logout",
        element: <LogoutCallback />,
        errorElement,
      },
      {
        path: "/st",
        element: <HomePage lang={Language.en} />,
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/bt",
        element: <HomePage lang={Language.en} />,
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/zb",
        element: (
          <HomePage
            lang={Language.zh}
            secondaryPublicationId={PublicationShortname.SM}
            navTabBarIndex={1}
          />
        ),
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/bh",
        element: <HomePage lang={Language.ms} />,
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/tm",
        element: (
          <HomePage
            lang={Language.ta}
            secondaryPublicationId={PublicationShortname.TABLA}
            navTabBarIndex={1}
          />
        ),
        loader: fetchContext,
        errorElement,
      },
      {
        // This path is used for single title TM for NLB
        path: "/tm-single",
        element: <HomePage lang={Language.ta} />,
        loader: fetchContext,
        errorElement,
      },
      {
        // This path is used for single title SM for NLB
        path: "/sm-single",
        element: <HomePage lang={Language.zh} />,
        loader: fetchContext,
        errorElement,
      },
      {
        // This path is used for single title ZB for NLB
        path: "/zb-single",
        element: <HomePage lang={Language.zh} />,
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/bm",
        element: <HomePage lang={Language.ta} />,
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/mm",
        element: <HomePage lang={Language.ta} />,
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/tabla",
        element: (
          <HomePage
            lang={Language.en}
            secondaryPublicationId={PublicationShortname.TM}
            navTabBarIndex={2}
          />
        ),
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/sm",
        element: (
          <HomePage
            lang={Language.zh}
            secondaryPublicationId={PublicationShortname.ZB}
            navTabBarIndex={2}
          />
        ),
        loader: fetchContext,
        errorElement,
      },
      {
        path: "/unauthenticated",
        element: <UnauthenticatedPage />,
        errorElement,
      },
      {
        path: "/subscribe",
        element: <SubscribePage />,
        errorElement,
      },
      {
        path: "/concurrency",
        element: <ConcurrencyPage />,
        errorElement,
      },
      {
        path: "/error",
        element: <ErrorPage />,
        errorElement,
      },
      {
        path: "/corpCallback/:svc",
        element: <CorpCallbackPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "*",
        element: <ErrorPage response={{ statusCode: 404 }} />,
        errorElement,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
