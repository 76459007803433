import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import dayjs from "dayjs";
import { PublicationShortname } from "src/app/auth/serviceIdentifier";

import { TVisitorContext } from "./viewerContext";

const EP_GA_CLICK_CATEGORY: Record<PublicationShortname, string> = {
  [PublicationShortname.BH]: "bh",
  [PublicationShortname.BT]: "bt",
  [PublicationShortname.SM]: "sm",
  [PublicationShortname.ST]: "st",
  [PublicationShortname.TM]: "tm",
  [PublicationShortname.ZB]: "zb",
  [PublicationShortname.BM]: "bm",
  [PublicationShortname.MM]: "mm",
  [PublicationShortname.TABLA]: "tabla",
  [PublicationShortname.DEFAULT]: "",
};

export enum GAClickAction {
  CLICK = "click",
  LOGIN = "login",
  CLOSE = "close",
}

export const GTM_ID = "GTM-KSTH25ZC";

export enum EP_GA_EVENT_NAME {
  PAGE_VIEW = "pages_page_view",
  CUSTOM = "custom_epaper_event",
}

// this constant used for chapter1 and pageName fields in GA event
export const PAGE_NAME = {
  ONBOARDING: "onboarding",
  PORTAL: "portal",
  SUBSCRIBE: "subscribe",
};

/**
 *
 * @param date Date to be parsed
 * @returns {string} Google Analytics date format in string
 */
export function getTrackerDateString(date: Date): string {
  return dayjs(date).format("YYYY-MM-DD");
}

/**
 * get the Google Analytics authentication status
 * @param visitorCategory vistiorCategory from the visitorContext
 * @returns status whether the visitor is authenticated or not
 */
export function getGAAuthenticationStatus(
  visitorCategory: TVisitorContext["visitorCategory"],
): "success" | "failed" {
  return visitorCategory === "Anonymous" ? "failed" : "success";
}

/**
 *
 * @param pubId
 * @returns
 */
export function getGAPublicationName(pubId: PublicationShortname) {
  return EP_GA_CLICK_CATEGORY[pubId];
}

/**
 * customized Google Analytics hooks
 */
export function useEpGTMDispatch(): ({
  eventName,
  eventDetail,
}: {
  eventName: string;
  eventDetail: Record<string, string>;
}) => void {
  const gtmDispatch = useGTMDispatch();

  const customDispatch = ({
    eventName,
    eventDetail,
  }: {
    eventName: string;
    eventDetail: Record<string, string>;
  }) => {
    gtmDispatch({
      event: "gaEvent",
      event_name: eventName,
      event_details: eventDetail,
    });
  };

  return customDispatch;
}
